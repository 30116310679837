import './App.css';
import Test from'./Test.js';

function App() {
  return (
    <div className="App">
      <header className="App-header">
     <Test company="Beracah Consulting Services">Chennai</Test>
     
      </header>
    </div>
  );
}

export default App;
